import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { GeneratedDocumentDetails } from '../../document-gen-dialog.component';

@Component({
  selector: 'ch-surgery-plus-card-maker',
  templateUrl: './surgery-plus-card-maker.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SurgeryPlusCardMakerComponent implements OnInit {
  @Input()
  documentDetails: GeneratedDocumentDetails;

  claimsFaxLine: string;
  logoUrl: SafeUrl;
  styleUrl: SafeStyle;
  coverageStart: Date;
  coverageEnd: Date;

  constructor(private sanitizer: DomSanitizer) {
    this.claimsFaxLine = environment.claimsFaxLine;

    // the reasoning here is that angular mangles the styles
    // and we don't want that when we generate the document
    // and send it to be rendered as a PDF
    this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(
      environment.blobStorage + '/html2pdf/surgery-plus.png'
    );

    this.styleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.blobStorage + '/html2pdf/surgery-plus-card.css'
    );

    this.coverageStart = new Date(new Date().getFullYear(), 0, 1);
    this.coverageEnd = new Date(new Date().getFullYear(), 11, 31);
  }

  ngOnInit() {
    console.log(this.documentDetails);
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseMemberIconComponent } from '../base-member-icon/base-member-icon.component';

@Component({
  selector: 'ch-supervisorneeded-member-icon',
  templateUrl: './supervisorneeded-member-icon.component.html',
  styleUrls: ['./supervisorneeded-member-icon.component.scss'],
  providers: [],
})
export class SupervisorNeedMemberIconComponent
  extends BaseMemberIconComponent
  implements OnInit
{
  get getToolTip(): string {
    return (
      'Transfer To Supervisor Immediately' +
      (this.member?.supervisorNeededNotes != null
        ? ':\n' + this.member?.supervisorNeededNotes
        : '!')
    );
  }
  get getIcon(): string {
    return 'report_problem';
  }

  constructor() {
    super();
  }

  ngOnInit(): void {}
}

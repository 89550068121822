import { NgModule } from '@angular/core';
import { MaterialModule } from 'carehub-shared/material.module';
import { SharedModule } from 'carehub-shared/shared.module';
import { AddMedicalPlanCoverageDialogComponent } from './components/controls/panels/add-medical-plan-coverage-dialog/add-medical-plan-coverage-dialog.component';
import { EligibilityPreferredInfoComponent } from './components/controls/panels/eligibility-preferred-info/eligibility-preferred-info.component';
import { PartnerInfoComponent } from './components/controls/panels/partner-info/partner-info.component';
import { MobileMemberIconComponent } from './components/member/member-banner/mobile-member-icon/mobile-member-icon.component';
import { SupervisorNeedMemberIconComponent } from './components/member/member-banner/supervisorneeded-member-icon/supervisorneeded-member-icon.component';
import { WebMemberIconComponent } from './components/member/member-banner/web-member-icon/web-member-icon.component';
@NgModule({
  imports: [SharedModule, MaterialModule],
  exports: [
    SupervisorNeedMemberIconComponent,
    MobileMemberIconComponent,
    WebMemberIconComponent,
    PartnerInfoComponent,
    AddMedicalPlanCoverageDialogComponent,
    EligibilityPreferredInfoComponent,
  ],
  entryComponents: [],
  declarations: [
    SupervisorNeedMemberIconComponent,
    MobileMemberIconComponent,
    WebMemberIconComponent,
    PartnerInfoComponent,
    AddMedicalPlanCoverageDialogComponent,
    EligibilityPreferredInfoComponent,
  ],
})
export class MembersSubWithoutRoutingModule {}

import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { BlobResult } from '../carehub/models/common/blobresult';
import { UploadObject } from './models/uploadobject';

/**
 *
 * @param http the injected http client for the service
 * @param url the upload url
 * @param upload the file to upload
 * @param metadata additional metadata (optional)
 * @returns Subject which completes when the upload finishes
 */
export function uploadFile(
  http: HttpClient,
  url: string,
  upload: UploadObject,
  metadata: any
) {
  if (!upload.file) {
    throw Error('no file specified to upload');
  }

  const subject = new Subject<void>();
  const fileToUpload = upload.file;
  const formData: any = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);

  const h = metadata
    ? {
        UploadMetadata: JSON.stringify(metadata),
      }
    : undefined;

  http
    .post(url, formData, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders(h),
    })
    .subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total) {
          upload.progress = Math.round((100 * event.loaded) / event.total);
        }
      } else if (event.type === HttpEventType.Response) {
        const response = <BlobResult>event.body;
        upload.url = response.blobAddress;
        subject.next();
        subject.complete();
      }
    });

  return subject;
}

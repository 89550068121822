import {
  APP_INSIGHTS_DEFAULTS,
  BASE_ENVIRONMENT,
  OIDC_DEFAULTS,
} from './defaults';
import { IEnvironment } from './environment.interface';

// in the "prod" configuration the environment-specific settings are injected
// via the appsettings.service.ts
export const environment: IEnvironment = {
  ...BASE_ENVIRONMENT,
  production: true,
  gateway: '',
  logoEnvText: '',
  environmentName: 'Production',
  oidc: {
    ...OIDC_DEFAULTS,
  },
  appInsights: {
    ...APP_INSIGHTS_DEFAULTS,
    instrumentationKey: '76875d03-7206-471d-8873-a578b8374440',
  },
};

import { HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from 'carehub-environment/environment';
import { SharedState, User } from 'carehub-root/shared/state/shared.reducer';
import { AppInsightService } from 'carehub-shared/services/app-insights.service';
import * as fromShared from 'carehub-shared/state/index';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

/** defines the behavior for the error logging method */
export interface ErrorLoggingOptions {
  /** should the error be recorded in an external aggregation source, ie app insights. defaults to {@link true} */
  logExternal: boolean;
  /** should the user see a toast notification about the error. defaults to {@link false} */
  showUserNotification: boolean;
  properties: { [key: string]: string };
  measures: { [key: string]: number };
}
/** global error catch and logger */
@Injectable({
  providedIn: 'root',
})
export class GlobalErrorService implements ErrorHandler {
  private user: User = null;
  private get defaultOptions(): ErrorLoggingOptions {
    return {
      logExternal: true,
      showUserNotification: false,
      properties: {
        environment: environment.environmentName,
        api: environment.gateway,
        stsUrl: environment.oidc.authority,
        userDisplayName: this.user ? this.user.displayName : undefined,
        userId: this.user ? this.user.userGuid : undefined,
        userGroups:
          this.user && this.user.securityInfo
            ? this.user.securityInfo.groups
            : undefined,
        securityInfo:
          this.user && this.user.securityInfo
            ? JSON.stringify(this.user.securityInfo)
            : undefined,
      },
      measures: {},
    };
  }
  private lastErrorDate: number;
  public constructor(
    private sharedStore: Store<SharedState>,
    private loggingService: AppInsightService
  ) {
    this.sharedStore
      .pipe(
        select(fromShared.getCurrentUser),
        filter((user) => user && user.securityInfo != null),
        catchError((err) => {
          this.handleError(err, {
            properties: {
              action: 'Could net get current user for logging messages',
            },
          });
          return throwError(err);
        })
      )
      .subscribe((user) => {
        this.user = user;
      });
  }
  /**
   * global error handler method. behavior controlled by {@link options}
   * @param error the error to log
   * @param options the optional options object
   */
  handleError(error: any, options: Partial<ErrorLoggingOptions> = null): void {
    // all unhandled application errors are routed through here
    let opts = this.buildOptions(options);
    console.error(error);
    if (opts.logExternal) {
      this.loggingService.logException(error, opts.properties, opts.measures);
    }

    // to prevent error loops, only push message for new errors within a second
    const now = Date.now();
    if (!this.lastErrorDate || now - this.lastErrorDate > 1000) {
      this.lastErrorDate = now;
      if (opts.showUserNotification) {
        this.sharedStore.dispatch(new sharedActions.SetCurrentError(error));
      }
    }
  }

  /**
   * enables the error handling
   * @param options the options for exception logging, if thrown
   * @param doStuff the callback to execute
   */
  tryTo(options: Partial<ErrorLoggingOptions>, doStuff: () => void): void {
    try {
      doStuff();
    } catch (err) {
      this.handleError(err, options);
    }
  }

  /** callback for http errors to centralize logging and feedback */
  handleHttpError(request: HttpRequest<any>, error: any): void {
    const options: Partial<ErrorLoggingOptions> = {
      showUserNotification: true,
      properties: {
        requestUrl: request.url,
        requestUrlParameters: JSON.stringify(request.params),
      },
    };

    this.handleError(error, options);
  }

  private buildOptions(
    options: Partial<ErrorLoggingOptions>
  ): ErrorLoggingOptions {
    return {
      ...this.defaultOptions,
      ...options,
      properties: {
        ...this.defaultOptions.properties,
        ...(options ? options.properties : undefined),
      },
      measures: {
        ...this.defaultOptions.measures,
        ...(options ? options.measures : undefined),
      },
    };
  }
}

import { Injectable } from '@angular/core';
import { UsersApiService } from 'carehub-api/users-api.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserNameService {
  private lookup = new Map<string, string>();

  constructor(private userService: UsersApiService) {
    this.userService.getUserLookup().subscribe((res) => {
      for (let u of res) {
        this.lookup.set(u.id, u.name);
      }
    });
  }

  getUserName(userGuid: string): Observable<string> {
    if (!userGuid || !this.lookup.has(userGuid)) {
      return of('Unknown');
    }

    return of(this.lookup.get(userGuid));
  }
}

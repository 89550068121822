import { AfterViewInit, Directive, OnInit } from '@angular/core';
import { BaseComponent } from 'carehub-root/shared/components/base-component';
import { SmartListCriteria, SmartListResult } from 'carehub-shared/smartlist';
import { Utils } from 'carehub-shared/utils';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive()
export abstract class FilterableComponent<TFilterObjectType, TResultObjectType>
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  isLoading: boolean = true;

  dataSource$: Observable<SmartListResult<TResultObjectType>>;
  dataSource: SmartListResult<TResultObjectType>;
  smartListCriteria: SmartListCriteria = SmartListCriteria.default();

  private _filter: Partial<TFilterObjectType>;

  public get filter(): Partial<TFilterObjectType> {
    return this._filter;
  }

  public set filter(value: Partial<TFilterObjectType>) {
    this._filter = { ...this._filter, ...value };
    this.filter$.next(this._filter);
  }

  public filter$: Subject<Partial<TFilterObjectType>> = new Subject<
    Partial<TFilterObjectType>
  >();

  /**
   * Sets a filter property and immediately runs loadData. If you need to apply
   * multiple filters before loading, set the defaultFilters in the constructor.
   * @param property the name of the filter prop
   * @param value the value to set
   */
  public onFilter(property: keyof TFilterObjectType, value: any) {
    this.filter[property] = value;
    this.loadData();
  }

  public onSmartListFilter(value: any) {
    this.smartListCriteria.filter = value;
    this.loadData();
  }

  public onDateFilter(property: keyof TFilterObjectType, date: string) {
    const tempDate: Date = new Date(date);
    this.onFilter(property, Utils.formatLocalOffset(tempDate));
  }

  public onPage(payload: { pageIndex: number; pageSize: number }) {
    Object.assign(this.smartListCriteria, payload);
    this.loadData(false);
  }

  public onSort(payload: { sortField: string; sortDirection: string }) {
    Object.assign(this.smartListCriteria, payload);
    this.loadData();
  }

  /**
   * requests data based on the current filter values
   * @param resetPage a flag to reset the page count, if the filtering criteria has changed
   */
  loadData(resetPage: boolean = true) {
    if (resetPage) {
      this.smartListCriteria.pageIndex = 0;
    }
    this.isLoading = true;
    this.filter$.next(this.filter);
  }

  constructor(
    public defaultCriteriaOverrides: Partial<SmartListCriteria>,

    filterDefaults?: Partial<TFilterObjectType>,
    private lazyInit: boolean = false
  ) {
    super();

    this.smartListCriteria = {
      ...this.smartListCriteria,
      ...defaultCriteriaOverrides,
    };

    this._filter = {
      ...this._filter,
      ...filterDefaults,
    };
  }

  abstract filterMethod(
    criteria: SmartListCriteria,
    filter: Partial<TFilterObjectType>
  ): Observable<SmartListResult<TResultObjectType>>;

  ngOnInit() {
    if (!this.filterMethod) {
      // the base-associator-new needs this hack for now
      // as it derrives from this class but makes no attempt to provide a filter
      console.warn('filterMethod must be specified');
      this.filterMethod = () => of(null);
    }

    // this is a bit convoluted but hear me out
    const subject = new Subject<SmartListResult<TResultObjectType>>();

    // We want to decouple filterMethod invocations so it is independent
    // of the number of subscribers.
    this.dataSource$ = subject;

    let current: Subscription = null;
    this.filter$.pipe(debounceTime(100)).subscribe((filter) => {
      // this behaves sorta like switchMap, but without chaining the observables,
      // if this http call has not yet completed, it will be cancelled
      current?.unsubscribe();
      current = this.filterMethod(this.smartListCriteria, filter)
        .pipe(take(1))
        .subscribe((res) => {
          subject.next(res);
        });
    });

    // Some consumers are using `dataSource$ | async` while others
    // are directly using the resulting `this.dataSource` collection produced by this
    // subscribe() so we have to make both work
    this.dataSource$.subscribe((data) => {
      this.isLoading = false;
      this.dataSource = data;
    });
  }

  ngAfterViewInit() {
    if (!this.lazyInit) {
      this.loadData();
    }
  }

  onDestroy() {
    this.filter$.complete();
  }
}

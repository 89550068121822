import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';

import { HTTP_NOAUTH } from 'carehub-root/shared/HTTP_NOAUTH';
import { SecurityUser } from './models/securityuser';

@Injectable({
  providedIn: 'root',
})
export class SecurityUsersApiService {
  constructor(@Inject(HTTP_NOAUTH) private http: HttpClient) {}

  getSecurityUserByUserId(
    accessToken: string,
    userId: string
  ): Observable<SecurityUser> {
    const url = environment.gateway + `/secapi/api/SecurityUsers/${userId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    };

    return this.http.get<SecurityUser>(url, httpOptions);
  }
}

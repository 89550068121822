import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  static initialized = false;
  constructor(private authService: AuthService) {}

  async load(): Promise<void> {
    if (AppInitializerService.initialized === true) {
      return Promise.resolve();
    }

    AppInitializerService.initialized = true;
    this.authService.initialize();
  }
}

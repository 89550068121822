import { AsyncPipe, DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from 'carehub-environment/environment';
import { AppBusyHttpInterceptor } from 'carehub-shared/interceptors/appbusy-http.interceptor';
import { AuthHttpInterceptor } from 'carehub-shared/interceptors/auth-http.interceptor';
import { HttpDynamicInterceptingHandler } from 'carehub-shared/interceptors/http-dynamic-intercepting.handler';
import { AgePipe } from 'carehub-shared/pipes/age.pipe';
import { AppInsightService } from 'carehub-shared/services/app-insights.service';
import { WindowEventConsumerService } from 'carehub-shared/services/window-event-consumer.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppRoutingModule } from './app-routing.module';
import { MembersSubWithoutRoutingModule } from './business-modules/member-services-mgmt/members/members-sub-without-routing.module';
import { ActivitiesSubModule } from './business-modules/shared/activities/activities-sub.module';
import { EntityHistorySubModule } from './business-modules/shared/entity-history/entity-history-sub.module';
import { MemberPlansSubModule } from './business-modules/shared/member-plans/member-plans-sub.module';
import { TasksSubModule } from './business-modules/shared/tasks/tasks-sub.module';
import { AppComponent } from './core/app.component';
import { AboutComponent } from './core/components/about/about.component';
import { CallContextSwapComponent } from './core/components/call-validation/call-context-swap/call-context-swap.component';
import { FrameContextService } from './core/components/call-validation/call-context-swap/frame-context.service';
import { CallValidationComponent } from './core/components/call-validation/call-validation/call-validation.component';
import { CaseSelectionComponent } from './core/components/call-validation/case-selection/case-selection.component';
import { MemberPreviewComponent } from './core/components/call-validation/member-preview/member-preview.component';
import { MemberSelectionComponent } from './core/components/call-validation/member-selection/member-selection.component';
import { HeaderComponent } from './core/components/controls/header/header.component';
import { MasterSearchComponent } from './core/components/controls/master-search/master-search.component';
import { NavComponent } from './core/components/controls/nav/nav.component';
import { ErrorComponent } from './core/components/error/error.component';
import { HealthComponent } from './core/components/health/health.component';
import { HelpComponent } from './core/components/help/help.component';
import { HomeComponent } from './core/components/home/home.component';
import { ImpersonateComponent } from './core/components/impersonate/impersonate.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { LoginRedirectComponent } from './core/components/redirects/login-redirect.component';
import { LogoutRedirectComponent } from './core/components/redirects/logout-redirect.component';
import { SilentRedirectComponent } from './core/components/redirects/silent-redirect.component';
import { TestComponent } from './core/components/test/test.component';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';
import { INTERNAL_API_GATEWAY } from './rest-services';
import { HTTP_NOAUTH } from './shared/HTTP_NOAUTH';
import { AppInitializerService } from './shared/services/app-settings.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnauthorizedComponent,
    NavComponent,
    HeaderComponent,
    LoginRedirectComponent,
    LogoutRedirectComponent,
    SilentRedirectComponent,
    NotFoundComponent,
    AboutComponent,
    HealthComponent,
    MasterSearchComponent,
    TestComponent,
    HelpComponent,
    ErrorComponent,
    ImpersonateComponent,
    CallContextSwapComponent,
    CallValidationComponent,
    MemberSelectionComponent,
    CaseSelectionComponent,
    MemberPreviewComponent,
  ],
  entryComponents: [ImpersonateComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          // this causes all sorts of havoc with our existing code patterns
          // we aren't immutable purists, whatever
          strictActionImmutability: false,
          strictStateImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    SharedModule,
    AppRoutingModule,
    TasksSubModule,
    MemberPlansSubModule,
    ActivitiesSubModule,
    EntityHistorySubModule,
    NgxMatSelectSearchModule,
    MembersSubWithoutRoutingModule,
  ],
  providers: [
    AsyncPipe,
    AppInsightService,
    DatePipe,
    FrameContextService,

    {
      provide: APP_INITIALIZER,
      useFactory: (init: AppInitializerService) => () => init.load(),
      deps: [AppInitializerService],
      multi: true,
    },

    {
      provide: INTERNAL_API_GATEWAY,
      useFactory: () => environment.gateway,
    },

    {
      // does NOT send the token on outgoing http requests.
      // use when communicating with 3rd party services
      provide: HTTP_NOAUTH,
      deps: [HttpBackend, Injector],
      useFactory: (backend: HttpBackend, injector: Injector) => {
        return new HttpClient(
          new HttpDynamicInterceptingHandler(backend, injector, {
            excludes: [AuthHttpInterceptor],
          })
        );
      },
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppBusyHttpInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },

    WindowEventConsumerService,
    AgePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    console.log(
      '***************************************************************************'
    );
    console.log(
      '**************************** AppModule Started ****************************'
    );
    console.log(
      '***************************************************************************'
    );
  }
}

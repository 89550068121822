import { environment } from './environments/environment';

/**
 * overwrites parts of the environment global with values from a remote host
 */
export async function configureEnvironment(): Promise<void> {
  console.log('configuring environment BEFORE app bootstrap');

  if (!window.origin.includes('localhost')) {
    try {
      const res = await fetch('/assets/appSettings.php');
      const settings = await res.text();
      // just to guard against any nonsense if you run this locally
      // and angular returns the whole file text
      if (!settings.startsWith('<')) {
        handleSettings(settings);
      }
    } catch (err) {
      return console.log('error handling environment overrides', err);
    }
  }

  return;
}

function handleSettings(text: any) {
  const json = JSON.parse(text);
  if (json) {
    for (const entry of Object.keys(json)) {
      const value: string = json[entry];
      const parts = entry.split(':');

      let count = 0;
      let environmentContainer: any = environment;

      for (const keyPart of parts) {
        count++;

        if (count === parts.length) {
          console.log(
            `Replacing entry with Key: ${keyPart} \n` +
              `  Old: '${environmentContainer[keyPart]}'\n` +
              `  New: '${value}'\n`
          );
          environmentContainer[keyPart] = value;
        } else {
          let tempContainer = environmentContainer[keyPart];
          if (!tempContainer) {
            environmentContainer[keyPart] = {};
            tempContainer = environmentContainer[keyPart];
          }
          environmentContainer = tempContainer;
        }
      }
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { DismissNotificationsRequest } from 'carehub-api/models/common/dismissnotificationsrequest';
import { UserNotification } from 'carehub-api/models/common/usernotification';
import { UserNotificationApiService } from 'carehub-api/usernotification-api.service';
import { AuthService } from 'carehub-root/shared/services/auth.service';
import { Utils } from 'carehub-root/shared/utils';
import * as fromRoot from 'carehub-root/state/app.state';
import { BaseComponent } from 'carehub-shared/components/base-component';
import * as fromShared from 'carehub-shared/state';
import * as sharedActions from 'carehub-shared/state/shared.actions';
import { HeaderOptions, User } from 'carehub-shared/state/shared.reducer';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ImpersonateComponent } from '../../impersonate/impersonate.component';

// 30s polling interval
const POLLING_INTERVAL = 30 * 1000;

@Component({
  selector: 'ch-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends BaseComponent implements AfterViewInit {
  currentBusinessModuleId$: Observable<string>;
  user$: Observable<User>;
  headerOptions$: Observable<HeaderOptions>;
  headerOptions: HeaderOptions;

  notifications: UserNotification[] = [];
  notificationCount = 0;
  notificationsPanelHidden = true;
  notificationsMin = Number.MAX_SAFE_INTEGER;
  notificationsMax = 0;

  // shows a little banner over the carehub icon
  // to visually indicate what env we're in
  hint = '';

  // on document click, if this panel does not contain the clicked target
  // then close the panel
  @ViewChild('notification_panel')
  notificationsPanel: ElementRef;

  @ViewChild('notifications_button')
  notificationsButton: ElementRef;

  get routerLink() {
    return this.headerOptions.iconNavigationLink;
  }

  get shouldShowModules() {
    return this.headerOptions.shouldShowModules;
  }

  get shouldShowSearch() {
    return this.headerOptions.shouldShowSearch;
  }

  get shouldShowNotifications() {
    return this.headerOptions.shouldShowNotifications;
  }

  get shouldShowImpersonator() {
    return this.headerOptions.shouldShowImpersonator;
  }

  get shouldShowHelp() {
    return this.headerOptions.shouldShowHelp;
  }

  constructor(
    private sharedStore: Store<fromRoot.State>,
    private router: Router,
    private authService: AuthService,
    private userNotifications: UserNotificationApiService,
    public dialog: MatDialog
  ) {
    super();
    this.headerOptions$ = this.sharedStore.pipe(
      select(fromShared.getHeaderOptions)
    );
    this.headerOptions$.subscribe((opts) => (this.headerOptions = opts));

    this.user$ = this.sharedStore.pipe(select(fromShared.getCurrentUser));

    this.currentBusinessModuleId$ = this.sharedStore.pipe(
      select(fromShared.getCurrentBusinessModuleId),
      map((val) => (val ? String(val) : null))
    );

    this.userNotifications.getRecent().subscribe((info) => {
      this.processNotifications(info.recent);
      this.notificationCount = info.total;
      this.notifications = [...info.recent] || [];

      setTimeout(
        () => this.pollForUpdates(),
        POLLING_INTERVAL + Math.random() * 100
      );
    });

    fromEvent(document, 'click').subscribe((e: MouseEvent) => {
      // ignore, we don't need to intercept this event.
      if (this.notificationsPanelHidden || !this.notificationsPanel) {
        return;
      }

      // todo: outside the bounding rect? rather than element-wise?
      let el = <Element>this.notificationsPanel.nativeElement;
      if (!el.contains(<Element>e.target)) {
        // collapse the panel if they click outside
        this.notificationsPanelHidden = true;
      }
    });

    if (environment && environment.logoEnvText) {
      this.hint = environment.logoEnvText;

      if (Utils.isLocal()) {
        this.hint = 'LOCAL';
      }
    }
  }

  ngAfterViewInit() {
    // We need to sync up the Current Business Module
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const firstPart = event.urlAfterRedirects
          .split('/')
          .filter((val) => val)[0];

        const route: any = this.router.config.find(
          (entry: any) => entry.path === firstPart
        );

        this.sharedStore.dispatch(
          new sharedActions.SetCurrentBusinessModuleId(null)
        );

        if (route) {
          console.log(
            `Setting Current Business Module Directly for Routing Event to ${route.businessModuleId}.`
          );
          this.sharedStore.dispatch(
            new sharedActions.SetCurrentBusinessModuleId(route.businessModuleId)
          );
        }
      }
    });
  }

  toggleNotificationsPanel(e: Event) {
    this.notificationsPanelHidden = !this.notificationsPanelHidden;

    // required: requests permissions as part of a user gesture
    if ('Notification' in window) {
      if (Notification.permission == 'default') {
        Notification.requestPermission();
      }
    }

    e.stopPropagation();
  }

  private processNotifications(list: UserNotification[]) {
    for (let n of list) {
      this.notificationsMax = Math.max(
        this.notificationsMax,
        n.userNotificationId
      );

      this.notificationsMin = Math.min(
        this.notificationsMin,
        n.userNotificationId
      );
    }
  }

  loadMoreNotifications(e: Event) {
    if (this.notificationsMin > 0) {
      this.userNotifications
        .loadMore(this.notificationsMin)
        .subscribe((prev) => {
          this.processNotifications(prev);
          this.notifications.push(...prev);
        });
    }

    e.preventDefault();
  }

  notificationClicked(n: UserNotification, e: Event) {
    if (n.link) {
      this.notificationsPanelHidden = true;
      this.router.navigateByUrl(n.link);

      e.preventDefault();
    }
  }

  pollForUpdates() {
    this.userNotifications
      .getUpdates(this.notificationsMax)
      .subscribe(($new) => {
        if ($new) {
          this.processNotifications($new);
          this.showDesktopNotification($new);

          this.notificationCount += $new.length;
          this.notifications.unshift(...$new);
        }

        // add in a little jitter so the backend hit isn't so uniform.
        setTimeout(
          () => this.pollForUpdates(),
          POLLING_INTERVAL + Math.random() * 100
        );
      });
  }

  private showDesktopNotification($new: UserNotification[]) {
    if ('Notification' in window) {
      if (Notification.permission == 'granted') {
        if ($new.length > 0) {
          let n = new Notification('CareHub Notifications', {
            body: 'You have ' + $new.length + ' new notifications to review.',
          });

          n.onclick = function () {
            window.focus();
          };
        }
      }
    }
  }

  businessModuleChanged(e: MatSelectChange) {
    this.sharedStore.dispatch(
      new sharedActions.SetCurrentBusinessModuleId(+e.value)
    );
  }

  private dismissNotifications(ids: number[]) {
    let req = new DismissNotificationsRequest();
    req.ids = ids;

    this.userNotifications.dismissNotifications(req).subscribe(() => {
      for (let id of req.ids) {
        const idx = this.notifications.findIndex(
          (u) => u.userNotificationId === id
        );

        if (idx != -1) {
          this.notifications.splice(idx, 1);
          this.notificationCount -= 1;
        }
      }
    });
  }

  dismissNotification(item: UserNotification, e: Event) {
    this.dismissNotifications([item.userNotificationId]);
  }

  dismissAll(e: Event) {
    this.dismissNotifications(
      this.notifications.map((n) => n.userNotificationId)
    );

    this.loadMoreNotifications(e);
  }

  canImpersonate(): boolean {
    return this.authService.canImpersonate();
  }

  onImpersonate() {
    this.dialog.open(ImpersonateComponent, {
      width: '600px',
    });
  }

  login() {
    console.log(`Starting login...`);
    this.sharedStore.dispatch(new sharedActions.Login());
  }

  refreshTokens() {
    this.authService.collectUserPermissions();
  }

  logout() {
    console.log(`Starting logout...`);
    this.sharedStore.dispatch(new sharedActions.Logout());
  }

  onDestroy() {}
}

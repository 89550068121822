<mat-card>
  <mat-card-title>Help</mat-card-title>
  <div class="grid-menu-container">
    <div>
      <a [routerLink]="['/about']">
        <mat-icon>info</mat-icon><span>&nbsp;About</span>
      </a>
    </div>
    <div>
      <a [routerLink]="['/health']">
        <mat-icon>local_hospital</mat-icon><span>&nbsp;Health</span>
      </a>
    </div>
    <div>
      <a [routerLink]="['/test']">
        <mat-icon>live_help</mat-icon><span>&nbsp;Control Test</span>
      </a>
    </div>
    <div>
      <a
        href="https://jira-edhc.atlassian.net/wiki/spaces/ETK/pages/234160129/Glossary"
        target="_blank"
      >
        <mat-icon>format_list_bulleted</mat-icon
        ><span
          >&nbsp;Glossary&nbsp;<mat-icon class="external">
            open_in_new
          </mat-icon></span
        >
      </a>
    </div>
  </div>
</mat-card>

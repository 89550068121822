import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AboutApiService } from 'carehub-api/about-api.service';
import { SettingsEntry } from 'carehub-api/models/settingsentry';
import { SettingsApiService } from 'carehub-api/settings-api.service';
import * as fromRoot from 'carehub-root/state/app.state';
import * as fromShared from 'carehub-shared/state';
import { User } from 'carehub-shared/state/shared.reducer';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['../test.component.scss'],
})
export class AboutComponent implements OnInit {
  settings$: Observable<SettingsEntry[]>;
  about$: Observable<any>;
  user$: Observable<User>;
  environmentSettings: any;

  constructor(
    private sharedStore: Store<fromRoot.State>,
    private aboutService: AboutApiService,
    private settingsService: SettingsApiService
  ) {}

  ngOnInit() {
    this.settings$ = this.settingsService.getSettings();
    this.about$ = this.aboutService.getAboutDetails();
    this.environmentSettings = environment;
    this.user$ = this.sharedStore.pipe(select(fromShared.getCurrentUser));
  }
}
